<template>
  <section class="capibSection">
    <div class="container mt-5 mb-5">
      <div class="row d-flex flex-row-reverse">
        <div class="col-lg-12 col-md-12 mt-5 mb-5 text-center">
          <h2 class="subTitle">Contact <span>Us</span></h2>
          <p class="capibDesc">
            Join the blockie's community!
          </p>
        </div>

        <div class="col-lg-12 col-md-12 text-center">
          <span>
            <a class="nav-item mr-4 large" href="https://twitter.com/blockies_nft" target="_blank"><i class="fab fa-twitter"></i></a>
            <a class="nav-item mr-4 large" href="https://discord.gg/rRCCBUYCvR" target="_blank"><i class="fab fa-discord"></i></a>
            <a class="nav-item large" href="https://www.instagram.com/blockies_klv/" target="_blank"><i class="fab fa-instagram"></i></a>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "aboutSection",
};
</script>
