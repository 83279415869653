<template>
  <section>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-5 text-center">
          <h2 class="subTitle">NFTnomics</h2>
        </div>
        <div class="col-lg-12 col-md-12 mb-5 text-center">
          <p class="capibDesc">Check out our proposal.</p>
        </div>
        <div class="col-lg-12 col-md-12">
          <ul class="list-group">
            <li class="list-group-item">-Blockies will be released in <a href="https://klevernft.com/">Klevernft.com</a> </li>
            <li class="list-group-item">-Total supply: 10,000 nft's</li>
            <li class="list-group-item">-Check blockies at <a href="https://kleverscan.org/asset/BLCK-38KS">kleverscan.org</a> </li>
          </ul>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "portfolio",
  data() {
    return {
    };
  },
};
</script>
