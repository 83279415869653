<template>
    <section class="mt-5">
        <div class="container mt-5 mb-5 custom-bg">
            <div class="row d-flex align-items-center">

                <div class="col-md-8 ">
                    <img class="img-fluid" v-tilt="{
                      reverse: true,
                      transition: true,
                      speed: 500,
                      perspective: 1200,
                      easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
                    }" src="../assets/mainblockie.png" alt="" width="600" />
                </div>
                <div class="col-md-4 mt-3">
                    <h1 class="stack">Welcome to the world of Blockies</h1>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'initialSection',
        mounted() {
            this.toggleBodyClass('addClass', 'custom-bg2');
        },
        methods: {
            toggleBodyClass(addRemoveClass, className) {
                const el = document.body;

                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
        }
    }
</script>
<style>
    .custom-bg2 {
        background-image: url('~@/assets/back.png') !important;
        /* background: linear-gradient(56deg, rgba(226,255,253,1) 0%, rgba(255,210,215,1) 100%) !important;  */
        /* -webkit-background-size: 100% !important; 
        -moz-background-size: cover !important; 
        -o-background-size: cover !important; 
        background-size: cover !important; 
        background-position: center center !important; 
        background-repeat: no-repeat !important; 
        background-attachment: fixed !important;  */
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-attachment: fixed !important;
        background-size: cover !important;
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
    }
</style>