<template>
  <section>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-5 text-center">
          <h2 class="subTitle">Why get a Blockie?</h2>
        </div>
        <div class="col-lg-12 col-md-12 mb-5 text-center">
          <p class="capibDesc">With the acquisition of a blockie you are demonstrating 3 simple things.</p>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5">
          <div class="card card-custom rotate-left">
            <div class="card-body text-center mt-3">
              <img src="../assets/web.png" width="180" />
              <h5 class="card-title text-dark red">Helping Kids</h5>
              <p class="capibDesc text-left">
                We believe that everyone has the right to a good education and by acquiring a blocky you are helping children who really need it to continue studying.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5">
          <div class="card card-custom">
            <div class="card-body text-center mt-3 ">
              <img src="../assets/business.png" width="180" />
              <h5 class="card-title red">Getting collectible NFT</h5>
              <p class="capibDesc text-left">
                Many of our blockies were created with web technology (Javascript) and totally randomized. But there is a part of them that were created 100% by hand, we merge our creative side with our geek side and we hope you like some of the designs that we propose.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5">
          <div class="card card-custom rotate-right">
            <div class="card-body text-center mt-3 ">
              <img src="../assets/mkt.png" width="180" />
              <h5 class="card-title red">Helping blockchain adoption</h5>
              <p class="capibDesc text-left">
                By creating a simple but beautiful cause project from scratch and without a gram of code, we are proving under the Klever chain that adoption is just around the corner and that it doesn't take much preparation to have a project of this magnitude!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "aboutSection",
  };
</script>