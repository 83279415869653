<template>
        <nav class="navbar navbar-expand-lg justify-content-center">
            <span  class="navbar-brand mr-0">
                <img src="../assets/logo.png" alt="" width="300">
            </span>
            <!-- <button class="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar2">
                <span class="navbar-toggler-icon"></span>
            </button> -->
            <!-- <div class="navbar-collapse collapse justify-content-between " id="collapsingNavbar2">
                <ul class="navbar-nav mx-auto text-center">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Home</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#">About</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Services</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Portfolio</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Contact</a>
                    </li>

                </ul>
            </div> -->
        </nav>
</template>

<script>
    
    export default {
        name: 'Navbar',
    }
</script>