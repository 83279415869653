<template>
  <!-- <section class="capibSection" :style="{'background-image': 'url(' + require('../assets/me.png') + ')'}"> -->
  <section class="capibSection mt-5">
    <div class="container mt-5 mb-5">
      <div class="row d-flex flex-row-reverse">
        <div class="col-lg-12 col-md-12 mt-5 mb-5 text-center">
          <h2 class="subTitle">About <span>Blockies</span></h2>
        </div>
      </div>
      <div class="row d-flex flex-row-center">
        <div class="col-lg-8 col-md-8 text-left justify-content-center">
          <div
            class="card turnRight card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body m-5">
              <h5>What is Blockies?</h5>
              <p class="capibDesc">
                Hi, we are Blockies, a new NFT project with a noble cause from México!
              </p>
              <p class="capibDesc">
                We're a small group of software engineers, supporters of the evergrowing 
                blockchain technologies, and early #KLV adopters. We're here since the 
                beginning and know the capabilities of this amazing project.
              </p>
              <p class="capibDesc">
                Since the introduction of NFT's, we're curious of the utility that some 
                early projects offered, and saw great opportunities for useful, fast and 
                secure development, but we didn't know how to start.
              </p>
              <p class="capibDesc">
                Since the announcement of Klever NFT technology, we finally had the 
                opportunity to start a project of our own, as Klever have always said
                 'Crypto Made Simple', we think they can also bring 'NFT's Made Simple'.
              </p>
              <p class="capibDesc">
                We're just starting, a true beginning of a project, but we wan't to 
                demostrate that you can have little knowledge in blockchain and still 
                develop and create.
              </p>
              <p class="capibDesc">
                We want to grow and help others to grow, so our project has a noble cause.
                 Most of the earnings from minting will help in local (ATM) programs that
                aim to keep kids stay in school.
              </p>
              <p class="capibDesc">
                We need and have to start localy, but we know we can and hopefully we will
                extend this help to kids in need from all the world. As we grow in the
                blockchain knowledge, we hope the earnings help grow other people.
              </p>
              <p class="capibDesc">
                This project will be for us a complete success if at least we can help 1 kid,
                 but for us it's already succesful, as we already start developing with the
                Klever NFT technology. The first stone is set.
              </p>
              <p class="capibDesc">
                We know we have a slow and rough start, but we will work daily to ensure this
                initial project flourish, and help next projects even easier and faster. 
                We all can grow together, we need to help each other.
              </p>
              <p class="capibDesc mb-5">
                Soon we will announce more details, and we will answer any doubts, read any feedback and suggestions in our social media.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-4 d-flex order-first align-items-center justify-content-center"
        >
          <img
            class="img-fluid"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
            src="../assets/me.png"
            alt=""
            width="300"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "aboutSection",
};
</script>
